<template>
  <transition name="fade">
    <div v-if="visible && success === false" class="toast-container">
      <div class="toast-content">{{ message }}</div>
    </div>
    <div
      v-else-if="visible && success === true"
      class="toast-container-success"
    >
      <div class="toast-content">Ολοκλήρωση</div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      default: 3000, // Toast duration in milliseconds
    },
    success: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        this.visible = false;
        this.$emit("close");
      }, this.duration);
    },
  },
};
</script>

<style scoped>
.toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  width: auto;
}
.toast-container-success {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;
  background-color: #36f452;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  width: auto;
}

.toast-content {
  display: inline-block;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
