<template>
  <div v-if="errorMessage === 'ok'">
    <div class="container">
      <h1 class="review-title">
        Η επιλογή σας υποβλήθηκε επιτυχώς!
      </h1>
      <p>Ευχαριστούμε!</p>
      <div v-if="shouldCheckSupportForLift" class="support-message">
        Πρέπει να ελέγξουμε το ενδεχόμενο του ανυψωτικού.
      </div>
      <div v-if="shouldCheckSupport" class="support-message">
        Πρέπει να ελέγξουμε το μέγεθος και τον όγκο.
      </div>
    </div>
  </div>
  <div v-else-if="errorMessage !== 'ok'">
    <!-- Toast for error message -->
    <Toast
      v-if="showToast"
      :message="errorMessage"
      :success="errorMessage === 'ok'"
      @close="showToast = false"
    />
    <!-- Single Form Container -->
    <div class="form-container">
      <h2>Δρομολόγηση παραγγελίας cargo</h2>

      <!-- Date Field (only if 'no' is selected) -->
      <div>
        <label for="date">Επιλέξτε ημερομηνία:</label>
        <select v-model="formData.date" id="date" required>
          <option
            v-for="date in formData.availableDates"
            :value="date"
            :key="date"
            >{{ formatDate(date) }}</option
          >
        </select>
      </div>

      <!-- Time Field -->
      <div>
        <label for="time">Επιλέξτε ώρα:</label>
        <select v-model="formData.time" id="time" required>
          <option v-for="time in timeWindows" :value="time" :key="time">{{
            time
          }}</option>
        </select>
      </div>

      <!-- Address Field -->
      <div>
        <label for="address">Διεύθυνση:</label>
        <input
          class="address-input"
          type="text"
          v-model="formData.address"
          id="address"
          :disabled="true"
          required
          autocomplete="shipping street-address"
        />
      </div>

      <!-- Floor Field -->
      <div>
        <label for="floor">Όροφος:</label>
        <select v-model="formData.floor" required>
          <option value="0">Επιλέξτε όροφο</option>
          <option value="0">Ισόγειο</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">Άλλο</option>
        </select>
      </div>

      <!-- Elevator Field (only if 'other' floor is selected) -->
      <div v-if="formData.floor && formData.floor > 0">
        <label>Υπάρχει ασανσέρ ;</label>
        <select v-model="formData.elevator" required>
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </select>
      </div>

      <!-- Parcel Fits in Elevator (only if elevator is 'yes') -->
      <div v-if="formData.elevator === 'yes' && formData.floor > 0">
        <label>Η παραγγελία σας μπορεί να μεταφερθεί από το ασανσέρ ;</label>
        <select v-model="formData.parcelFitsInElevator" required>
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </select>
      </div>

      <!-- Parcel Fits Through Stairs (if elevator 'no' or 'yes' and fit 'no') -->
      <div
        v-if="
          formData.floor > 0 &&
            (formData.elevator === 'no' ||
              (formData.elevator === 'yes' &&
                formData.parcelFitsInElevator === 'no'))
        "
      >
        <label>H παραγγελία σας μπορεί να μεταφερθεί από τις σκάλες ;</label>
        <select v-model="formData.parcelFitsThroughStairs" required>
          <option value="yes">Ναι</option>
          <option value="no">Όχι</option>
        </select>
      </div>

      <!-- Extra Notes -->
      <div>
        <label for="extraNotes">Σημειώσεις (Προαιρετικό):</label>
        <textarea v-model="formData.extraNotes" id="extraNotes"></textarea>
      </div>

      <!-- Submit Button -->
      <button @click="handleSubmit" class="submit-btn">Submit</button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Toast from "../../graphic/Toast.vue";

export default {
  name: "CargoPickupForm",
  components: {
    Toast,
  },
  props: {
    errorMessage: {
      type: String,
      require: false,
    },
    data: {
      type: Object,
      require: true,
    },
    reschedule: {
      type: Function,
      require: true,
    },
  },
  data() {
    return {
      formData: this.data,
      voucher: "",
      formSubmitted: false,
      timeWindows: this.generateTimeWindows(8, 16, 4), // Time options generated from 08:00 to 16:00
      isMobile: false,
      rescheduleFunc: this.reschedule,
      showToast: !!this.errorMessage,
    };
  },
  watch: {
    errorMessage(newVal) {
      // Show toast when errorMessage changes
      if (newVal) {
        this.showToast = true;
      }
    },
  },
  mounted() {
    this.checkViewportWidth();
    window.addEventListener("resize", this.checkViewportWidth);
  },
  computed: {
    shouldCheckSupportForLift() {
      return (
        this.formData.floor > 0 &&
        (this.formData.elevator === "no" || !this.formData.elevator) &&
        (this.formData.parcelFitsThroughStairs === "no" ||
          !this.formData.parcelFitsThroughStairs)
      );
    },
    shouldCheckSupport() {
      return (
        this.formData.floor > 0 &&
        (this.formData.elevator === "yes" || this.formData.elevator === true) &&
        (this.formData.parcelFitsInElevator === "no" ||
          !this.formData.parcelFitsInElevator)
      );
    },
  },
  methods: {
    checkViewportWidth() {
      this.isMobile = window.innerWidth < 800;
    },
    formatDate(date) {
      return moment(date)
        .locale("el")
        .format("dddd YYYY-MM-DD");
    },
    // Generate time options from 08:00 to 16:00
    generateTimeWindows(startHour, endHour, windowSize) {
      let timeWindows = [];
      let currentHour = startHour;

      while (currentHour + windowSize <= endHour) {
        let startWindow = this.formatTime(currentHour);
        let endWindow = this.formatTime(currentHour + windowSize);
        timeWindows.push(`${startWindow} - ${endWindow}`);
        currentHour++;
      }

      return timeWindows;
    },
    formatTime(hour) {
      // Adds leading zero if necessary
      return hour < 10 ? `0${hour}:00` : `${hour}:00`;
    },
    handleSubmit() {
      if (!this.formData.address) {
        this.errorMessage = "Παρακαλώ συμπλήρωσε διεύθυνση.";
        this.showToast = true;
        return;
      }
      this.formSubmitted = true;
      this.formData.parcelFitsThroughStairs =
        this.formData.parcelFitsThroughStairs === "yes" ? true : false;
      this.formData.parcelFitsInElevator =
        this.formData.parcelFitsInElevator === "yes" ? true : false;
      this.formData.elevator = this.formData.elevator === "yes" ? true : false;
      this.rescheduleFunc(this.formData);
    },
  },
};
</script>

<style scoped>
/* Container and Form Styles */
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45vw;
  margin: 50px auto;
  padding: 30px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.address-input:disabled {
  background-color: #f0f0f0; /* Light gray background */
  color: #7a7a7a; /* Gray text color */
  border-color: #dcdcdc; /* Gray border */
}

/* Input fields */
input,
select,
textarea {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

/* Submit Button */
button {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

/* Support Message */
.support-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Container and Form Styles */
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90vw; /* Use most of the screen width on mobile */
  max-width: 400px; /* Restrict the maximum width */
  margin: 20px auto; /* Center the form with margin on all sides */
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 16px; /* Slightly larger font for better readability */
}

/* Headings */
h2 {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

/* Input fields */
input,
select,
textarea {
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Ensure input fields take full width */
  box-sizing: border-box;
  font-size: 1rem; /* Standard font size for readability */
}

/* Input focus styles for better interaction */
input:focus,
select:focus,
textarea:focus {
  border-color: #4caf50;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.2); /* Green glow on focus */
}

/* Textarea resize control */
textarea {
  resize: vertical; /* Allow only vertical resizing */
  min-height: 80px; /* Ensure a reasonable height for notes */
}

/* Submit Button */
button {
  padding: 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  width: 100%; /* Make button take full width */
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}

.error-title {
  height: 50px;
  line-height: 34px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 26px;
  width: 490px;
  letter-spacing: 0px;
}

/* Mobile-specific styles */
@media only screen and (max-width: 600px) {
  .form-container {
    padding: 15px;
    width: 95vw; /* Use 95% of the viewport width on small screens */
  }

  .error-title {
    line-height: 29px;
    height: 46px;
    font-size: 19px;
    width: 328px;
  }
  h2 {
    font-size: 1.1rem; /* Slightly smaller heading for mobile */
  }

  input,
  select,
  textarea {
    font-size: 0.9rem; /* Slightly smaller input font size for mobile */
  }

  button {
    font-size: 0.9rem; /* Slightly smaller button text for mobile */
  }
}
</style>
