<template>
  <div class="flex-wrapper-app">
    <Navbar />
    <main class="cargo-pickup-form">
      <Loader v-if="isLoading" />

      <div v-else>
        <cargo-pickup-form
          :errorMessage="errorMessage"
          :data="formData"
          :reschedule="reschedule"
        />
      </div>
    </main>
    <Footer class="footer" />
  </div>
</template>

<script>
import Navbar from "../pages-sections/Navbar.vue";
import CargoPickupForm from "./Cargo/CargoPickupForm.vue";
import Loader from "../loader/Loader.vue";
import axios from "axios";
import Footer from "../pages-sections/Footer.vue";

export default {
  name: "CargoPickupPage",
  components: {
    Navbar,
    CargoPickupForm,
    Loader,
    Footer,
  },
  data() {
    return {
      formData: {
        nextAvailableRoute: "",
        date: "2024-09-05",
        availableDates: [],
        time: "",
        address: "",
        floor: "0",
        elevator: "no",
        parcelFitsInElevator: "no",
        parcelFitsThroughStairs: "no",
        extraNotes: "",
      },
      voucher: "",
      isLoading: false,
      errorMessage: null,
    };
  },
  mounted() {
    this.voucher = this.$route.params?.voucher;
    this.getDetails();
  },
  methods: {
    async getDetails() {
      await axios
        .post(
          `${process.env.VUE_APP_COURIER_API}/user/cargoDetails/${this.voucher}`
          // `http://localhost:8000/user/cargoDetails/${this.voucher}`
        )
        .then((r) => {
          this.formData.date = r?.data.nextAvailableDate;
          this.formData.availableDates = r?.data.availableDates?.map(
            (d) => d.label
          );

          this.formData.address = r?.data.address;
          this.formData.extraNotes = r?.data.notes;
          this.formData.floor = r?.data.floor;
        })
        .catch(
          (err) => (this.errorMessage = err.response?.data?.error_description)
        );
    },
    async reschedule(data) {
      await axios
        .post(
          `${process.env.VUE_APP_COURIER_API}/user/cargoReschedule/${this.voucher}`,
          // `http://localhost:8000/user/cargoReschedule/${this.voucher}`,
          data
        )
        .then(() => {
          this.errorMessage = "ok";
        })
        .catch(
          (err) => (this.errorMessage = err.response?.data?.error_description)
        );
    },
  },
};
</script>

<style lang="scss" scoped>
$white: #fff;
$orange: #f68b24;
$orange8: rgb(246 139 36 / 80%);
$black: #363636;
$warning: #f44336;

.review-main {
  background-size: cover !important;
}

.cargo-pickup-form {
  flex-grow: 1;
}

@mixin flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 733px) {
  .review-main {
    padding: 15px 0 224px;
    //margin-left: 55px;
  }
  form input {
    font-size: 14px;
  }
}

@media screen and (min-width: 733px) and (max-width: 961px) {
  .review-main {
    padding: 50px 0 433px;

    .container {
      margin: 0 59px;
    }
  }
}

@media screen and (max-width: 961px) {
  .review-main {
    justify-content: center;
    width: 100%;

    .container {
      width: 100%;
    }
  }
}

@media screen and (min-width: 961px) and (max-width: 1500px) {
  .container {
    margin-left: 64px !important;
  }
  .review-main {
    padding: 100px 0 256px;
  }
}

@media screen and (min-width: 1500px) {
  .container {
    margin-left: 64px !important;
  }
  .review-main {
    padding: 100px 0 256px;
  }
}
</style>
